import { request } from "../../utils/APIUtils";
import { IAlteracaoSenha } from "../types/IAlteracaoSenha";

export function updatePassword(passwords: IAlteracaoSenha) {
	return request({
		 path: "/senha/alterar",
		 method: 'POST',
		 body: JSON.stringify(passwords)
	});
}

export function forgotPassword(email: string) {
	return request({
		 path: "/senha/recuperar",
		 method: 'POST',
		 body: email
	});
}

export function alterarSenhaInterno(payload: any) {
	return request({
		 path: "/senha/alterar/admin",
		 method: 'PUT',
		 body: JSON.stringify(payload)
	});
}