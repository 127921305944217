import React from 'react';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import { RouteComponentProps } from 'react-router';
import LoginPage from './LoginPage';

type Props = RouteComponentProps;

const LoginPageComplete: React.FC<Props> = (props) => {
    return (
      <React.Fragment>
			<MenuBar />
			<div className="page-body">
				<LoginPage {...props} />
			</div>
			<Footer />
		</React.Fragment>
    );
}

export default LoginPageComplete;