import { request } from "../../utils/APIUtils";
import { IFacebookLogin } from "../types/IFacebookLogin";

export function loginFacebook(payload: IFacebookLogin) {
	return request({
		path: `/login/facebook`,
		method: 'POST',
		body: JSON.stringify(payload)
	});
}

export function loginGoogle(payload: any) {
	return request({
		path: `/login/google`,
		method: 'POST',
		body: JSON.stringify(payload)
	});
}