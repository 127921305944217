import { gerarPedidoTransfeera } from "../../../core/api/PagamentoTransfeera";
import { ICompraRequest } from "../../../types/ICompraRequest";
import { PedidoRetorno } from "../../../types/PedidoRetorno";
import PagamentoController from "../PagamentoController";

export default class PagamentoTransfeeraController implements PagamentoController {
   private qrcodeBase64: string | undefined;
   
   private pixCopiaCola: string | undefined;

   setQrcodeBase64 = (qrcodeBase64: string | undefined): void => {
      this.qrcodeBase64 = qrcodeBase64;
   };

   getQrcodeBase64 = (): string | undefined => {
      return this.qrcodeBase64;
   };

   setPixCopiaCola = (pixCopiaCola: string | undefined): void => {
      this.pixCopiaCola = pixCopiaCola;
   };

   getPixCopiaCola = (): string | undefined => {
      return this.pixCopiaCola;
   };


   getNomeParceiro = (): string => {
      return 'Transfeera';
   }

   getRedirectUrlQueryParams = (): string => {
      return '';
   };

   processarPagamento = async (pedido: ICompraRequest): Promise<PedidoRetorno> => {
      try {
         const response = await gerarPedidoTransfeera(pedido);
         this.qrcodeBase64 = response.qrcodeBase64;
         this.pixCopiaCola = response.pixCopiaCola;

         return {
            errors: undefined,
            succeeded: true,
         };
      } catch (error) {
         return {
            errors: error,
            succeeded: false,
         };
      }
   };
}
