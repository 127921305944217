import { Reducer } from 'redux';
import { CampanhaSelecionadaState } from '../../types/redux';
import { LOAD_CAMPANHA, CLEAR_CAMPANHA } from '../action/actions';

const initialState: CampanhaSelecionadaState = {
	idCampanha: undefined,
  numeroCampanha: undefined,
  email: undefined,
  idStatus: undefined,
  qtde: undefined,
  valorLiquido: undefined,
  arquivoComprovanteNome: undefined
};

const campanhaReducer: Reducer<CampanhaSelecionadaState> = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAMPANHA:
      return {
        ...state,
        idCampanha: action.idCampanha,
        numeroCampanha: action.numeroCampanha,
        email: action.email,
        idStatus: action.idStatus,
        qtde: action.qtde,
        valorLiquido: action.valorLiquido,
        arquivoComprovanteNome: action.arquivoComprovanteNome
      };
    case CLEAR_CAMPANHA:
        return {
          ...state,
          idCampanha: undefined,
          numeroCampanha: undefined,
          email: undefined,
          idStatus: undefined,
          qtde: undefined,
          valorLiquido: undefined,
          arquivoComprovanteNome: undefined
			  };
    default:
      return state;
  }
};

export default campanhaReducer;