import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MenuBar from './components/MenuBar';
import Footer from './components/Footer';
import NotFoundPage from './pages/error/NotFoundPage';
import ProtectedPageAdmin from './components/ProtectedPageAdmin';
import NotFoundPageComplete from './pages/error/NotFoundPageComplete';
import { ACCESS_TOKEN } from './constants';
import { Store } from './redux/store';
import { LOAD_MOSTRAR_ALERTA_ATUALIZAR_PAGINA, LOAD_USER } from './redux/action/actions';
import LoadingPage from './components/LoadingPage';
import asyncComponent from './components/AsyncComponent';
import ScrollToTop from './components/ScrollToTop';
import { getCurrentUser } from './core/api/Usuario';
import AlertaAtualizarPagina from './core/components/AlertaAtualizarPagina';

// Themes
import './sass/rifatech.scss';
import ProtectedPageUser from './components/ProtectedPageUser';
import Dashboard from './components/dashboard/Dashboard';
import RedirectToNotFoundPage from './pages/error/RedirectToNotFoundPage';
import RedirecionarPaginaLegado from './components/RedirecionarPaginaLegado';
import PagamentoStripeController from './pages/pagamento/stripe/PagamentoStripeController';
import PagamentoPagseguroController from './pages/pagamento/pagseguro/PagamentoPagseguroController';
import { validarComprarGrupo } from './utils/PagamentoUtils';
import PagamentoTransfeeraController from './pages/pagamento/transfeera/PagamentoTransfeeraController';
import { getVersaoFrontend } from './utils/APIUtils';

const AsyncGerenciamentoRifasPage = asyncComponent(() => import('./pages/dashboard/gerenciamento_rifas/GerenciamentoRifasPage'));
const AsyncAlterarSenhaInternoPage = asyncComponent(() => import('./pages/dashboard/alterar_senha/AlterarSenhaInternoPage'));
const AsyncServidorEmailPage = asyncComponent(() => import('./pages/dashboard/servidor_email/ServidorEmailPage'));
const AsyncGerenciamentoUsuariosPage = asyncComponent(() => import('./pages/dashboard/gerenciamento_usuarios/GerenciamentoUsuariosPage'));
const AsyncRelatorioUsuariosPage = asyncComponent(() => import('./pages/dashboard/relatorio_usuarios/RelatorioUsuariosPage'));
const AsyncCadastrarTarifaPage = asyncComponent(() => import('./pages/dashboard/cadastrar_tarifa/CadastrarTarifaPage'));
const AsyncParametrosSistemaPage = asyncComponent(() => import('./pages/dashboard/parametros_sistema/ParametrosSistemaPage'));
const AsyncAprovarRifaPage = asyncComponent(() => import('./pages/dashboard/aprovar_rifa/AprovarRifaPage'));
const AsyncEmailsMarketingPage = asyncComponent(() => import('./pages/dashboard/emails_marketing/EmailsMarketingPage'));

const RoutesDashboardAdmin: React.FC<any> = (props) => {
  return (
    <ProtectedPageAdmin {...props}>
      <Switch>
        <Route exact path="/admin" component={AsyncGerenciamentoRifasPage} />
        <Route exact path="/admin/alterarSenhaInterno.xhtml" component={AsyncAlterarSenhaInternoPage} />
        <Route exact path="/admin/servidorEmail.xhtml" component={AsyncServidorEmailPage} />
        <Route exact path="/admin/gerenciamentoUsuarios.xhtml" component={AsyncGerenciamentoUsuariosPage} />
        <Route exact path="/admin/relatorio_usuarios.xhtml" component={AsyncRelatorioUsuariosPage} />
        <Route exact path="/admin/cadastrar_tarifas.xhtml" component={AsyncCadastrarTarifaPage} />
        <Route exact path="/admin/parametrosSistema.xhtml" component={AsyncParametrosSistemaPage} />
        <Route exact path="/admin/gerenciamentoRifas.xhtml" component={AsyncGerenciamentoRifasPage} />
        <Route exact path="/admin/aprovarCampanha/:idRifa" component={AsyncAprovarRifaPage} />
        <Route exact path="/admin/emailsMarketing" component={AsyncEmailsMarketingPage} />
        <Route component={NotFoundPageComplete} />
      </Switch>
    </ProtectedPageAdmin>
  );
}

const AsyncDashboardIndexPage = asyncComponent(() => import('./pages/dashboard_usuario/index/DashboardIndexPage'));
const AsyncBilhetesCompradosPage = asyncComponent(() => import('./pages/bilhetes_comprados/BilhetesCompradosPage'));
const AsyncCriarRifaPage = asyncComponent(() => import('./pages/criar_rifa/CriarRifaPage'));
const AsyncEditarRifaPage = asyncComponent(() => import('./pages/editar_rifa/EditarRifaPage'));
const AsyncVerRifaPage = asyncComponent(() => import('./pages/ver_rifa/VerRifaPage'));
const AsyncCampanhasPage = asyncComponent(() => import('./pages/campanhas/CampanhasPage'));
const AsyncComprovantePage = asyncComponent(() => import('./pages/comprovante/ComprovantePage'));
const AsyncLiberarAcessoPage = asyncComponent(() => import('./pages/liberar_acesso/LiberarAcessoPage'));
const AsyncAcessosPage = asyncComponent(() => import('./pages/acessos/AcessosPage'));
const AsyncAnalyticsCampanhaPage = asyncComponent(() => import('./pages/analytics/AnalyticCampanhaPage'));
const AsyncAfiliacoesPage = asyncComponent(() => import('./pages/dashboard_usuario/afiliacoes/AfiliacoesPage'));
const AsyncIncluirAfiliacaoPage = asyncComponent(() => import('./pages/dashboard_usuario/incluir_afiliacao/IncluirAfiliacaoPage'));

const RoutesDashboardUsuario: React.FC<any> = (props) => {
  return (
		<ProtectedPageUser {...props}>
			<Dashboard>
				<Dashboard.Body>
					<Switch>
						<Route exact path="/dashboard" component={AsyncDashboardIndexPage} />
						<Route path="/dashboard/campanha/bilhetes/:numeroRifa" component={AsyncBilhetesCompradosPage} />
						<Route exact path="/dashboard/campanha/comprovante/:numeroRifa" render={(renderProps) => <AsyncComprovantePage tipo="transferencia" {...renderProps} /> } />
						<Route exact path="/dashboard/nfse/rifa/:numeroRifa" render={(renderProps) => <AsyncComprovantePage tipo="nfse" {...renderProps} /> } />
						<Route exact path="/dashboard/campanha/criar" component={AsyncCriarRifaPage} />
						<Route exact path="/dashboard/campanha/editar/:idRifa" component={AsyncEditarRifaPage} />
            <Route exact path="/dashboard/campanha/analytics/:numeroRifa" component={AsyncAnalyticsCampanhaPage} />
						<Route exact path="/dashboard/campanha/ver/:idRifa" component={AsyncVerRifaPage} />
						<Route exact path="/dashboard/liberar_acesso/:numeroRifa" component={AsyncLiberarAcessoPage} />
            <Route exact path="/dashboard/recuperar_senha" component={AsyncRecuperarSenhaPage} />
            <Route exact path="/dashboard/afiliacoes" component={AsyncAfiliacoesPage} />
            <Route exact path="/dashboard/afiliacao/incluir/:numeroRifa" component={AsyncIncluirAfiliacaoPage} />
						<Route exact path="/dashboard/campanhas" component={AsyncCampanhasPage} />
						<Route exact path="/dashboard/acessos" component={AsyncAcessosPage} />
            <Route exact path="/dashboard/painelRifeiro.xhtml" render={(renderProps) => <RedirecionarPaginaLegado path="/dashboard/painelRifeiro.xhtml" {...renderProps} /> } />
						<Route component={RedirectToNotFoundPage} />
					</Switch>
				</Dashboard.Body>
			</Dashboard>
		</ProtectedPageUser>
  );
}

const AsyncPaginaInicial = asyncComponent(() => import('./pages/index/PaginaInicial'));
const AsyncAlterarSenhaPage = asyncComponent(() => import('./pages/alterar_senha/AlterarSenhaPage'));
const AsyncCadastrarUsuarioPage = asyncComponent(() => import('./pages/cadastrar_usuario/CadastrarUsuarioPage'));
const AsyncCaptacaoPage = asyncComponent(() => import('./pages/captacao/CaptacaoPage'));
const AsyncConfirmarEmailUsuarioPage = asyncComponent(() => import('./pages/confirmar_email_usuario/ConfirmarEmailUsuarioPage'));
const AsyncConfirmarChavePixPage = asyncComponent(() => import('./pages/confirmar_chave_pix/ConfirmarChavePixPage'));
const AsyncConfirmarEntregaPage = asyncComponent(() => import('./pages/confirmar_entrega_premio/ConfirmarEntregaPage'));
const AsyncComprarPage = asyncComponent(() => import('./pages/comprar/ComprarPage'));
const AsyncLoginPage = asyncComponent(() => import('./pages/login/LoginPage'));
const AsyncLogoutPage = asyncComponent(() => import('./pages/logout/LogoutPage'));
const AsyncPagamentoRetornoPage = asyncComponent(() => import('./pages/pagamento_retorno/PagamentoRetornoPage'));
const AsyncPagamentoPage = asyncComponent(() => import('./pages/pagamento/PagamentoPage'));
const AsyncRifaPage = asyncComponent(() => import('./pages/rifa/RifaPage'));
const AsyncDesbloquearLoginPage = asyncComponent(() => import('./pages/desbloquear_login/DesbloquearLoginPage'));
const AsyncRecuperarSenhaPage = asyncComponent(() => import('./pages/recuperar_senha/RecuperarSenhaPage'));
const AsyncMailingListUnsubscribePage = asyncComponent(() => import('./pages/mailing_list/MailingListUnsubscribePage'));
const AsyncFacebookErrorPage = asyncComponent(() => import('./pages/erro_facebook/FacebookErrorPage'));
const pagamentoPagseguroController = new PagamentoPagseguroController();
const pagamentoStripeController = new PagamentoStripeController();
const pagamentoTransfeeraController = new PagamentoTransfeeraController();

const RoutesPublic: React.FC = () => {
  return (
    <div>
      <MenuBar />     
      
        <div className="page-body">
          <Switch>
            <Route exact path="/" component={AsyncPaginaInicial} />
            <Route exact path="/index.xhtml" component={AsyncPaginaInicial} />
            <Route path="/alterarSenha.xhtml" component={AsyncAlterarSenhaPage} />
            <Route exact path="/cadastrarUsuario.xhtml" component={AsyncCadastrarUsuarioPage} />
            <Route path="/captacao.xhtml" component={AsyncCaptacaoPage} />
            <Route path="/confirmar_email_usuario.xhtml" component={AsyncConfirmarEmailUsuarioPage} />
            <Route path="/confirmar_chave_pix" component={AsyncConfirmarChavePixPage} />
            <Route path="/confirmar_entrega_premio_rifa.xhtml" component={AsyncConfirmarEntregaPage} />
            <Route path="/desbloquear_login.xhtml" component={AsyncDesbloquearLoginPage} />
            <Route exact path="/comprar.xhtml" component={AsyncComprarPage} />
            <Route exact path="/login.xhtml" component={AsyncLoginPage} />
            <Route path="/logout" component={AsyncLogoutPage} />
            <Route exact path="/pagamento/retorno/:descricaoFormaPagamento/:parceiro" render={(renderProps) => 
                <AsyncPagamentoRetornoPage {...renderProps} qrcodeBase64={pagamentoTransfeeraController?.getQrcodeBase64()} 
                  pixCopiaCola={pagamentoTransfeeraController?.getPixCopiaCola()} />} />
            <Route exact path="/pagamento" render={(renderProps) => <AsyncPagamentoPage 
                {...renderProps}
                pagamentoPagseguroController={pagamentoPagseguroController} 
                pagamentoStripeController={pagamentoStripeController} 
                pagamentoTransfeeraController={pagamentoTransfeeraController} 
                validarComprarGrupo={validarComprarGrupo} 
                 /> } />
            <Route path="/rifa.xhtml" component={AsyncRifaPage} />
            <Route path="/([0-9]+).xhtml" component={AsyncRifaPage} />
            <Route path="/mailinglist/unsubscribe" component={AsyncMailingListUnsubscribePage} />
            <Route exact path="/erro_facebook" component={AsyncFacebookErrorPage}  />

				    {/* Legado */}
				    <Route path="/bilhetes/rifa/:numeroRifa" render={(props) => <RedirecionarPaginaLegado path="/bilhetes/rifa/:numeroRifa" {...props} /> } />
				    <Route exact path="/comprovante/rifa/:numeroRifa" render={(props) => <RedirecionarPaginaLegado path="/comprovante/rifa/:numeroRifa" {...props} /> } />
            <Route exact path="/nfse/rifa/:numeroRifa" render={(props) => <RedirecionarPaginaLegado path="/nfse/rifa/:numeroRifa" {...props} /> } />
				    <Route exact path="/criar_rifa.xhtml" render={(props) => <RedirecionarPaginaLegado path="/criar_rifa.xhtml" {...props} /> } />
            <Route exact path="/editar/rifa/:idRifa" render={(props) => <RedirecionarPaginaLegado path="/editar/rifa/:idRifa" {...props} /> } />
            <Route exact path="/ver/rifa/:idRifa" render={(props) => <RedirecionarPaginaLegado path="/ver/rifa/:idRifa" {...props} /> } />
				    <Route exact path="/liberar_acesso/:numeroRifa" render={(props) => <RedirecionarPaginaLegado path="/liberar_acesso/:numeroRifa" {...props} /> } />
				    <Route exact path="/minhasrifas.xhtml" render={(props) => <RedirecionarPaginaLegado path="/minhasrifas.xhtml" {...props} /> } />
            <Route exact path="/campanhas" render={(props) => <RedirecionarPaginaLegado path="/campanhas" {...props} /> } />
            <Route exact path="/acessos" render={(props) => <RedirecionarPaginaLegado path="/acessos" {...props} /> } />

            <Route component={NotFoundPage} />
          </Switch>
        </div>

        <Footer />
    </div>
  );
}

interface AppState {
  loading: boolean,
  pageVisible: boolean
}

let interval: ReturnType<typeof setInterval>;
const MINUTE_MS = 300000;

class App extends React.Component<any, AppState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      pageVisible: true
    }
  }

  componentDidMount() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    interval = setInterval(this.buscarVersaoMaisRecenteFrontend, MINUTE_MS);
    this.loadCurrentUser();
  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange)
    clearInterval(interval);
  }

  handleVisibilityChange = (): void => {
    if (document.visibilityState === 'visible') {
      this.setState({ pageVisible: true });
    } else {
      this.setState({ pageVisible: false });
    }
  }

  buscarVersaoMaisRecenteFrontend = (): void => {
    const { pageVisible } = this.state;

    if (pageVisible) {
      getVersaoFrontend()
      .then(response => {
        Store.dispatch({type: LOAD_MOSTRAR_ALERTA_ATUALIZAR_PAGINA, mostrar: response?.content 
          ? response.content !== process.env.REACT_APP_VERSION
          : false 
        })
      })
      .catch(err => {
        // ignora erro
      })
    }
  }

  // Carrega os dados do usuario para a store
  loadCurrentUser = () => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    if (!token)
      return;

    this.setState({ loading: true });

    getCurrentUser()
    .then(response => {
      Store.dispatch({type: LOAD_USER, currentUser: response});
      this.setState({ loading: false });
    })
    .catch(error => {
      localStorage.removeItem(ACCESS_TOKEN);
      this.setState({ loading: false });
    }); 
  }

  render() {
    const {loading} = this.state;

    if (loading)
      return <LoadingPage />

    return (
      <div>
        <AlertaAtualizarPagina />

        <Router basename="/app">
          <ScrollToTop>
            <Switch>
              <Route path="/dashboard" component={RoutesDashboardUsuario} />
              <Route path="/admin" component={RoutesDashboardAdmin} />
              <Route path="/" component={RoutesPublic} />
            </Switch>
          </ScrollToTop>
        </Router>
      </div>
    );
  }
}

export default App;
