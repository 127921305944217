import { combineReducers } from 'redux';
import userReducer from './user-reducer';
import paramsReducer from './parametros-reducer';
import campanhaReducer from './campanha-reducer';
import apiInformationReducer from './api-information-reducer';

const Reducers = combineReducers({
    userData: userReducer,
    parametros: paramsReducer,
    campanhaSelecionada: campanhaReducer,
    apiInformation: apiInformationReducer
});

export default Reducers;