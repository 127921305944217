import { Reducer } from 'redux';
import { LOAD_API_INFORMATION } from '../action/actions';
import { IApiInformation } from '../../core/types/IApiInformation';

const initialState: IApiInformation = {
};

const apiInformationReducer: Reducer<IApiInformation> = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_API_INFORMATION:
      return {
        ...state,
        ...action.apiInformation
      };
    default:
      return state;
  }
};

export default apiInformationReducer;