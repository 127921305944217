import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

interface MatchParams {
	idRifa?: string,
	numeroRifa?: string
}

interface ParentProps {
	path: string
}

type Props = ParentProps & RouteComponentProps<MatchParams>;

const RedirecionarPaginaLegado: React.FC<Props> = ({ match, path }: Props) => {
	const {
		idRifa,
		numeroRifa
	} = match.params;
	let to="/404";

	if (path === "/bilhetes/rifa/:numeroRifa")
		to = `/dashboard/campanha/bilhetes/${numeroRifa}`;
	else if (path === "/comprovante/rifa/:numeroRifa")
		to = `/dashboard/campanha/comprovante/${numeroRifa}`;
	else if (path === "/nfse/rifa/:numeroRifa")
		to = `/dashboard/nfse/rifa/${numeroRifa}`;
	else if (path === "/criar_rifa.xhtml")
		to = `/dashboard/campanha/criar`;
	else if (path === "/editar/rifa/:idRifa")
		to = `/dashboard/campanha/editar/${idRifa}`;
	else if (path === "/ver/rifa/:idRifa")
		to = `/dashboard/campanha/ver/${idRifa}`;
	else if (path === "/liberar_acesso/:numeroRifa")
		to = `/dashboard/liberar_acesso/${numeroRifa}`;
	else if (path === "/minhasrifas.xhtml" || path === "/campanhas" || path === "/dashboard/painelRifeiro.xhtml")
		to = `/dashboard/campanhas`;
	else if (path === "/acessos")
		to = "/dashboard/acessos";

	return (
		<Redirect to={to} />
	);
}

export default RedirecionarPaginaLegado;