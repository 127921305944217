import { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component<any, any> {
	componentDidUpdate(prevProps: any) {
		const {
			location
		} = this.props;

	  if (location.pathname !== prevProps.location.pathname) {
		 window.scrollTo(0, 0);
	  }
	}
 
	render() {
		const {
			children
		} = this.props;

	  return children;
	}
 }
 
 export default withRouter(ScrollToTop);