import React from 'react';

interface Props {
	children?: any,
	style?: any
}

const PaperTitle: React.FC<Props> = (props) => {
	if (!props.children)
		return null;

	return (
		<h4 className="header-title mb-3" style={(props.style) ? props.style : {}}>
			{props.children}
		</h4>
	);
}

export default PaperTitle;