import React from "react";
import { LOAD_MOSTRAR_ALERTA_ATUALIZAR_PAGINA } from "../redux/action/actions";
import { Store } from "../redux/store";

/**
 * Este componente é utilizado para carregar componentes de forma assíncrona.
 * 
 * @param importComponent 
 */
const asyncComponent = (importComponent: any) => {
  class AsyncComponent extends React.Component<any, any> {
    _isMounted = false;

    constructor(props: any) {
      super(props);

      this.state = {
        component: null
      };
    }

    async componentDidMount() {
      this._isMounted = true;

      importComponent()
        .then((cmp: any) => {
          if (this._isMounted) 
            this.setState({ component: cmp.default })
        })
        .catch((err: any) => {
          if (err.toString().indexOf('ChunkLoadError') > -1) {
            Store.dispatch({type: LOAD_MOSTRAR_ALERTA_ATUALIZAR_PAGINA, mostrar: true })
          }
        })
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      const {
        component: C
      } = this.state;

      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}

export default asyncComponent;