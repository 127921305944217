import { request } from "../../utils/APIUtils";
import { ICompraRequest } from "../../types/ICompraRequest";

export function gerarSessao(idRifa: string | number, quantidade: string | number) {
	return request({
		path: `/pagseguro/sessao?rifa_grupo=${idRifa}&comprar_grupo=${quantidade}`,
		method: 'GET'
	});
}

export function gerarPedidoPagSeguro(pedido: ICompraRequest) {
	return request({
		path: `/pagseguro/pagamento`,
		method: 'POST',
		body: JSON.stringify(pedido)
	});
}