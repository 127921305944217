import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { ApplicationState } from '../redux/store';
import { CurrentUserState } from '../types/redux';
import { getLink } from '../utils/FunctionUtils';
import LinkPainelControle from './LinkPainelControle';

interface ReduxProps {
	user: CurrentUserState
}

type Props = ReduxProps;

const MenuBar: React.FC<ReduxProps> = ({ user }: Props) => {
	const { isAuthenticated, currentUser } = user;

	return (
		<div id="public-menu" className="page-header">
			<Navbar expand="md" className="text-center w-100 navbar-dark">
				{/* Logo */}
				<Navbar.Brand href={process.env.REACT_APP_CONTENT_URL} className="menu-logo">
					<div className="navbar-logo">
						<img alt="Logo"
							src={`${process.env.PUBLIC_URL}/images/logo-menu.png`}
							style={{height: "40px"}} title="Logo" />
					</div>
				</Navbar.Brand>

				{/* Botão de menu para telas pequenas */}
            <Navbar.Toggle className="float-right" aria-controls="navbarSupportedContent">
					<i className="navbar-toggler uil-bars pr-0" />
				</Navbar.Toggle>

				{/* Links */}
            <Navbar.Collapse className="ml-auto mr-0" id="navbarSupportedContent" style={{flexGrow: 0}}>
					<Nav>
						<Link to="/comprar.xhtml"
								className="nav-item nav-link">
							{ (process.env.REACT_APP_NOME_SISTEMA === "Dhoe")
								? "Doar"
								: "Comprar"
							}
						</Link>
							
						<Link to={getLink("criar_campanha", undefined, undefined)}
							className="nav-item nav-link">Criar Campanha</Link>
						
						{ (isAuthenticated && currentUser) ? 
							<div className="d-md-flex">
								<LinkPainelControle currentUser={currentUser} />

								<Link to="/logout"
									className="nav-item nav-link">Sair</Link>
							</div>
							: <div className="d-md-flex">
								<Link to="/cadastrarUsuario.xhtml"
									className="nav-item nav-link">Cadastrar</Link>

								<Link to="/login.xhtml"
									className="nav-item nav-link">Entrar</Link>
							</div>
						}
						
                  <a href={process.env.REACT_APP_CONTENT_URL} className="nav-item nav-link">
							Voltar ao Site
                  </a>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
}

const mapStateToProps = (store: ApplicationState) => ({
	user: store.userData
});

export default connect(mapStateToProps)(MenuBar);