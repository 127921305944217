import React from 'react'
import { Link } from 'react-router-dom';
import { Perfil } from '../types/user';
import { getLink } from '../utils/FunctionUtils';

interface Props {
	currentUser: any
}

const LinkPainelControle: React.FC<Props> = ({ currentUser }: Props) => {
	if (currentUser.perfilId === Perfil.ADMINISTRADOR) {
		return (
			<Link to={getLink("dashboard_admin", undefined, undefined)} className="nav-item nav-link">
				Painel de Controle
			</Link>
		);
	}
	
	if (process.env.REACT_APP_ATIVAR_MODULO_DASHBOARD === "true") {
		return (
			<Link to="/dashboard" className="nav-item nav-link">
				Painel de Controle
			</Link>
		);
	}
	
	return (
		<>
			<Link to={getLink("listar_campanhas", undefined, undefined)} className="nav-item nav-link">
				Campanhas
			</Link>

			<Link to={getLink("gerenciar_acessos", undefined, undefined)} className="nav-item nav-link">
				Gerenciar Acessos
			</Link>

			<Link to={getLink("alterar_senha", undefined, undefined)} className="nav-item nav-link">
				Alterar Senha
			</Link>
		</>
	);
}

export default LinkPainelControle
