import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';
import { ApplicationState } from '../../redux/store'

interface ReduxProps {
	mostrarAlertaAtualizarPagina: boolean | undefined
}

type Props = ReduxProps

const AlertaAtualizarPagina = ({mostrarAlertaAtualizarPagina}: Props) => {
	const [mostrar, setMostrar] = useState(false)

	useEffect(() => {
		setMostrar(!!mostrarAlertaAtualizarPagina)
	}, [mostrarAlertaAtualizarPagina])

	return (
		<>
			{ mostrar
				? <div className="mostrar-alerta-global">
					<Alert variant="warning" className="fixed-top text-center" style={{ width: "100vw" }}>
							Esta é uma versão desatualizada desta página. Clique no botão atualizar do seu navegador <FontAwesomeIcon icon={faRedoAlt} style={{ fontSize: '0.8em' }} /> para visualizar a versão mais recente.
					</Alert>
				</div>
				: null
			}
		</>
	)
}

const mapStateToProps = (store: ApplicationState) => ({
	mostrarAlertaAtualizarPagina: store.parametros.mostrarAlertaAtualizarPagina
 });
 
 export default connect(mapStateToProps)(AlertaAtualizarPagina);
