/* eslint-disable import/prefer-default-export */
import { request } from "../../utils/APIUtils";
import { ICompraRequest } from "../../types/ICompraRequest";

export function gerarPedidoTransfeera(pedido: ICompraRequest) {
	return request({
		path: `/transfeera/pagamento`,
		method: 'POST',
		body: JSON.stringify(pedido)
	});
}