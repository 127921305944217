import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { IAPIResponse } from '../types/api';

interface Props {
	errors?: Array<IAPIResponse> | IAPIResponse | string,
	marginTop?: boolean,
	children?: JSX.Element[] | JSX.Element | null
}

const ErrorMessages: React.FC<Props> = ({ errors, marginTop, children }: Props) => {

	if (!errors)
		return null;

	if (typeof errors === 'string') {
		return (
			<Alert variant="danger" className={(marginTop === false) ? "" : "mt-4"}>
				{errors}

				{ children ?? null}
			</Alert>
		);
	}

	if (Array.isArray(errors)) {
		return (
			<Alert variant="danger" className={(marginTop === false) ? "" : "mt-4"}>
				<b style={{fontSize: "1.2em"}}>Ocorreram erros ao realizar a operação</b>
				{ errors.map((error, index) =>
					<div key={`${error.code}-${index+1}`}>
						{error.message ? `${error.message} ${error.code ? (`(${error.code})`) : ''}` : error}
					</div>
				)}

				{ children ?? null}
			</Alert>
		);
	}

	return (
		<Alert variant="danger" className={(marginTop === false) ? "" : "mt-4"}>
				{errors.message ? `${errors.message} ${errors.code ? (`(${errors.code})`) : ''}` : "Erro interno, tente novamente mais tarde."}

				{ children ?? null}
		</Alert>
	);
}

export default ErrorMessages;
