import { request } from "../../utils/APIUtils";
import { ICadastroUsuario } from "../types/ICadastroUsuario";

export function getCurrentUser() {
	return request({
		 path: "/usuario",
		 method: 'GET'
	});
}

export function confirmarEmailCadastro(key: string) {
	return request({
		 path: `/usuario/confirmaremail`,
		 method: 'POST',
		 body: key
	});
}

export function cadastrarUsuario(usuario: ICadastroUsuario) {
	return request({
		 path: "/usuario",
		 method: 'POST',
		 body: JSON.stringify(usuario)
	});
}