import { ICompraRequest } from "../../types/ICompraRequest";
import { IStringReq } from "../../types/IStringReq";
import { SessaoStripe } from "../../types/SessaoStripe";
import { request } from "../../utils/APIUtils";

export function gerarSessaoStripe(idRifa: string | number, quantidade: string | number, codigoAfiliacao: string | undefined): Promise<SessaoStripe> {
	const afiliacaoQuery = codigoAfiliacao ? `&codigoAfiliacao=${codigoAfiliacao}` : '';

	return request({
		path: `/stripe/sessao?rifa_grupo=${idRifa}&comprar_grupo=${quantidade}${afiliacaoQuery}`,
		method: 'GET'
	});
}

export function gerarPedidoStripe(pedido: ICompraRequest): Promise<IStringReq> {
	return request({
		path: `/stripe/pagamento`,
		method: 'POST',
		body: JSON.stringify(pedido)
	});
}