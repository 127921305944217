import { StripeCardElement, loadStripe } from '@stripe/stripe-js';
import { gerarPedidoStripe } from '../../../core/api/PagamentoStripe';
import { ICompraRequest } from '../../../types/ICompraRequest';
import { PedidoRetorno } from '../../../types/PedidoRetorno';
import PagamentoController from '../PagamentoController';

export default class PagamentoStripeController implements PagamentoController {
   public stripe = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ? loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) : null;

   private card: StripeCardElement | null | undefined;

   private clientSecret: string | null | undefined;

   private idSessaoStripe: string | null | undefined;

   private internalCode: string | null | undefined;

   public preencheuDadosCartao = false;

   setCard = (card: StripeCardElement | null | undefined): void => {
      this.card = card;
   };

   setClientSecret = (clientSecret: string | null | undefined): void => {
      this.clientSecret = clientSecret;
   };

   setIdSessaoStripe = (idSessaoStripe: string | null | undefined): void => {
      this.idSessaoStripe = idSessaoStripe;
   };

   setInternalCode = (internalCode: string | null | undefined): void => {
      this.internalCode = internalCode;
   }

   getNomeParceiro = (): string => {
      return 'Stripe';
   }

   getRedirectUrlQueryParams = (): string => {
      return '';
   };

   setPreencheuDadosCartao = (preencheuDadosCartao: boolean): void => {
      this.preencheuDadosCartao = preencheuDadosCartao;
   }

   processarPagamento = async (pedido: ICompraRequest): Promise<PedidoRetorno> => {
      try {
         const p: ICompraRequest = {
            ...pedido,
            externalCode: this.idSessaoStripe ?? undefined,
            internalCode: this.internalCode ?? undefined
         };
         const stripe = await this.stripe;
         // eslint-disable-next-line @typescript-eslint/no-unused-vars
         const response = await gerarPedidoStripe(p);
         const payload =
            stripe && this.card && this.clientSecret
               ? await stripe.confirmCardPayment(this.clientSecret, {
                    payment_method: {
                       card: this.card,
                       billing_details: {
                          email: pedido.comprador ? pedido.comprador.email : undefined,
                          name: pedido.comprador ? pedido.comprador.nome : undefined,
                          phone: pedido.comprador ? pedido.comprador.telefone : undefined,
                       },
                    },
                 })
               : undefined;

         if (payload?.error) {
            return {
               errors: `Falha no pagamento: ${payload.error.message}`,
               succeeded: false,
            };
         }

         return {
            errors: undefined,
            succeeded: true,
         };
      } catch (error) {
         return {
            errors: error,
            succeeded: false,
         };
      }
   };
}
