import React from 'react';
import './DashboardBody.scss';
import PaperTitle from '../../core/components/PaperTitle';

interface Props {
	children?: React.ReactNode,
	toggleMenu?: () => void,
	className?: string
}

const DashboardBodyTitle: React.FC<Props> = (props) => {
	return (
		<PaperTitle>
			{props.children}
		</PaperTitle>
	);
}

// Barra de navegação superior
class DashboardBody extends React.Component<Props> {
	static Title = DashboardBodyTitle;

	toggleMenu = () => {
		if (this.props.toggleMenu)
			this.props.toggleMenu();
	}

	render() {
		return (
			<div id="dashboard-body" className={this.props.className ? this.props.className : ""}>
				<div id="page-body-content">
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default DashboardBody;