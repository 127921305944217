import React from 'react';
import MenuBar from '../../components/MenuBar';
import Footer from '../../components/Footer';
import NotFoundPage from './NotFoundPage';

const NotFoundPageComplete: React.FC = () => {
    return (
      <div>
			<MenuBar />
			<NotFoundPage />
			<Footer />
		</div>
    );
}

export default NotFoundPageComplete;