import { gerarPedidoPagSeguro } from '../../../core/api/PagamentoPagSeguro';
import { ICompraRequest } from '../../../types/ICompraRequest';
import { PedidoRetorno } from '../../../types/PedidoRetorno';
import PagamentoController from '../PagamentoController';

export default class PagamentoPagseguroController implements PagamentoController {
   private urlBoleto: string | undefined;

   private tokenCartaoCredito: string | undefined;

   private senderHash: string | undefined;

   private sessionId: string | undefined;

   setTokenCartaoCredito = (tokenCartaoCredito: string | undefined): void => {
      this.tokenCartaoCredito = tokenCartaoCredito;
   };

   getSenderHash = (): string | undefined => {
      return this.senderHash;
   };

   setSenderHash = (senderHash: string | undefined): void => {
      this.senderHash = senderHash;
   };

   getSessionId = (): string | undefined => {
      return this.sessionId;
   };

   setSessionId = (sessionId: string | undefined): void => {
      this.sessionId = sessionId;
   };

   getNomeParceiro = (): string => {
      return 'PagSeguro';
   }

   getRedirectUrlQueryParams = (): string => {
      if (this.urlBoleto) {
         return `paymentLink=${this.urlBoleto}`;
      }

      return '';
   };

   processarPagamento = async (pedido: ICompraRequest): Promise<PedidoRetorno> => {
      const { dadosPagamento } = pedido;

      if (dadosPagamento) dadosPagamento.tokenCartaoCredito = this.tokenCartaoCredito;

      const payload: ICompraRequest = {
         ...pedido,
         dadosPagamento,
         senderHash: this.senderHash,
         sessionId: this.sessionId,
      };
      try {
         const response = await gerarPedidoPagSeguro(payload);
         this.urlBoleto = response.boletoURL;

         return {
            errors: undefined,
            succeeded: true,
         };
      } catch (error) {
         return {
            errors: error,
            succeeded: false,
         };
      }
   };
}
