import React from 'react';

const LoadingPage: React.FC = () => {
   return (
      <div className="banner-container text-center" style={{ backgroundColor: '#fff', minHeight: '70vh' }}>
         <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
         </div>
      </div>
   );
};

export default LoadingPage;
