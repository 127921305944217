import { loadApiInformation } from "../../redux/action";
import { Store } from "../../redux/store";
import { getApiVersion } from "../api/ApiInformation";
import { IApiInformation } from "../types/IApiInformation";

// Classe singleton enquanto a arquitetura não está bem definida
export class ApiInformationController {
	static instance: ApiInformationController;

	private constructor() {
		//console.log("constructor called!");
	}

	public static getInstance(): ApiInformationController {
		if (!ApiInformationController.instance) {
			ApiInformationController.instance = new ApiInformationController();
		}
		return ApiInformationController.instance;
	}

	initializeApiInformation() {
		getApiVersion()
		.then(response => {
			const apiInformation: IApiInformation = {
				version: response
			}

			// Carrega as informações para a store do Redux
			Store.dispatch(loadApiInformation(apiInformation))
		})
		.catch(error => {
			console.error(error);
		})
	}
}