import { request } from "../../utils/APIUtils";

export function desbloquearLogin(payload: any) {
	return request({
		 path: "/login/desbloquear",
		 method: 'POST',
		 body: JSON.stringify(payload)
	});
}

export function reenviarEmailDesbloqueioLogin(payload: any) {
	return request({
		 path: "/login/desbloquear/reenviaremail",
		 method: 'POST',
		 body: JSON.stringify(payload)
	});
}