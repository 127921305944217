import React from 'react';

interface Props {
	children?: any,
	className?: string,
	style?: Object
}

const PageTitle: React.FC<Props> = (props) => {
	if (!props.children)
		return null;

	return (
		<div className={"page-title-box " + (props.className ? props.className : "")}>
			<h4 className="page-title" style={ props.style ? props.style : {}}>
				{props.children}
			</h4>
		</div>
	);
}

export default PageTitle;