import { Reducer } from 'redux';
import { LOAD_USER, USER_LOGOUT } from '../action/actions';
import { CurrentUserState } from '../../types/redux';

const initialState: CurrentUserState = {
    isAuthenticated: false,
    currentUser: null
};

const userReducer: Reducer<CurrentUserState> = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.currentUser
      };
    case USER_LOGOUT:
        return {
          ...state,
          isAuthenticated: false,
          currentUser: null
        };
    default:
      return state;
  }
};

export default userReducer;