import React from 'react';
import { connect } from 'react-redux';
import DashboardNav from './DashboardNav';
import MenuAdministrador from './MenuAdministrador';
import DashboardBody from './DashboardBody';
import { BOOTSTRAP_MOBILE_BREAKPOINT } from '../../constants';
import MenuUsuario from './MenuUsuario';
import { CurrentUserState } from '../../types/redux';
import { Perfil } from '../../types/user';
import { ApplicationState } from '../../redux/store';

interface ParentProps {
	children?: React.ReactNode,
	type?: "user" | "admin",
	hideHeader?: boolean
}

interface ReduxProps {
	user: CurrentUserState,
}

type Props = ParentProps & ReduxProps;

interface State {
	showMenu: boolean,
	isMobileWidth: boolean
}

class Dashboard extends React.Component<Props, State> {
	static Nav = DashboardNav;

	static Body = DashboardBody;

	constructor(props: Props) {
		super(props);

		const isMobileWidth = (window.innerWidth <= BOOTSTRAP_MOBILE_BREAKPOINT);
		this.state = {
			isMobileWidth,
			showMenu: !isMobileWidth // menu lateral inicia escondido em mobiles
		}
	}

	componentDidMount() {
		window.addEventListener("resize", this.resizeWindow);
	}

  componentWillUnmount() {
		window.removeEventListener("resize", this.resizeWindow);
  }

  resizeWindow = () => {
	  const isMobileWidth = (window.innerWidth <= BOOTSTRAP_MOBILE_BREAKPOINT);
		this.setState({ 
			isMobileWidth,
			showMenu: !isMobileWidth
		 });
  }

	toggleMenu = () => {
		this.setState({ showMenu: !this.state.showMenu });
	}

	// Esconde o menu quando o usuário clica em qualquer parte fora do menu
	handleBodyClick = (event: any) => {
		if (this.state.isMobileWidth && this.state.showMenu) {
			this.setState({
				showMenu: false
			})
		}
	}

	render() {
		const usuarioLogadoEhAdmin = (this.props.user && this.props.user.currentUser && this.props.user.currentUser.perfilId === Perfil.ADMINISTRADOR) ?
			true
			: false;

		let children = [];
		if (this.props.children) {
			let elements = React.Children.toArray(this.props.children);
			for (let i in elements) {
				if (React.isValidElement(elements[i])) {
					let child = React.cloneElement(elements[i] as React.ReactElement<any>, { toggleMenu: this.toggleMenu })
					children.push(child);
				}
			}
		}

		return (
			<div id="dashboard">
				{/* Menu Principal Lateral */}
				{	(this.props.type === "admin" || usuarioLogadoEhAdmin) ?
					<MenuAdministrador show={this.state.showMenu}/>
					: <MenuUsuario show={this.state.showMenu}/>
				}

				{/* header que mostra o botão para o menu principal */}
				{	this.props.hideHeader ? 
					null :
					<div className={"panel-header panel-header-lg d-lg-none" + (this.state.showMenu ? " d-none" : "")}>
						<div className="container-fluid" onClick={this.toggleMenu}>
							<i className="navbar-toggler uil-bars"></i>

							<label id="navbar-text" className="mr-auto">Menu Principal</label>
						</div>
					</div>
				}

				<div id="dashboard-conteudo" 
					className={(this.state.showMenu ? 
						(this.state.isMobileWidth ? "menu-open menu-open-mobile" : "menu-open") 
						: "menu-closed") 
						+ ((this.props.type === 'admin') ? " dashboard-body-admin" : "")}
					onClick={this.handleBodyClick} >
					{children}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (store: ApplicationState) => ({
	user: store.userData,
});

export default connect(mapStateToProps)(Dashboard);