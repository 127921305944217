import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Perfil } from '../types/user';
import ProtectedPage from './ProtectedPage';

interface ParentProps {
    children?: any
}

type Props = RouteComponentProps & ParentProps;

const ProtectedPageAdmin: React.FC<Props> = (props) => {
    return <ProtectedPage perfilUsuario={Perfil.ADMINISTRADOR} {...props} />
}

export default ProtectedPageAdmin;