import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../../sass/form.scss';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getAccessToken } from '../../utils/APIUtils';
import Alert from 'react-bootstrap/Alert';
import { ACCESS_TOKEN, API_ERROR } from '../../constants';
import { loadCurrentUser, getLink, getLinkAdmin } from '../../utils/FunctionUtils';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import ErrorMessages from '../../components/ErrorMessages';
import ErrorBoundary from '../../components/ErrorBoundary';
import FacebookLoginButton from '../../core/components/FacebookLoginButton';
import GoogleLoginButton from '../../core/components/GoogleLoginButton';
import { reenviarEmailDesbloqueioLogin } from '../../core/api/LoginDesbloqueio';
import { forgotPassword } from '../../core/api/Senha';
import PageTitle from '../../core/components/PageTitle';
import { Perfil } from '../../types/user';

interface State {
	sending: boolean,
	errorMessage?: string,
	successMessage?: string,
	renderRecaptcha: boolean,
	loginBloqueado: boolean,
	sendingEmail: boolean
}

type Props = RouteComponentProps;

class LoginPage extends React.Component<Props, State> {
	private email: React.RefObject<any>;
	private senha: React.RefObject<any>;
	_isMounted: boolean = false;

	constructor(props: Props) {
		super(props);

		this.state = {
			sending: false,
			renderRecaptcha: false,
			loginBloqueado: false,
			sendingEmail: false
		}

		this.email = React.createRef();
      this.senha = React.createRef();
	}

	componentDidMount() {
		this._isMounted = true;

		if (process.env.REACT_APP_CAPTCHA_KEY)
			loadReCaptcha(process.env.REACT_APP_CAPTCHA_KEY);
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	recuperarSenha = (event: React.FormEvent) => {
		event.preventDefault();

		this.setStateIfMounted({ 
			...this.state,
			successMessage: undefined, 
			errorMessage: undefined 
		});

		const email = this.email.current.value;
		
		// Verifica se o e-mail está em branco
		if (!email) {
			this.setStateIfMounted({ 
				...this.state,
				errorMessage: "Preencha o campo e-mail" 
			});
			return;
		}
		
		// Chama a API para recuperar senha
		forgotPassword(email)
		.then(response => {
			this.setStateIfMounted({ 
				...this.state,
				successMessage: response.message 
			})
		})
		.catch(error => {
			let errorMessage = "Erro ao recuperar senha, tente novamente mais tarde.";

			if (error.content && error.content[0])
				errorMessage = error.content[0].message;

			this.setStateIfMounted({ 
				...this.state,
				errorMessage: errorMessage 
			});
		});
	}

	verifyCaptchaCallback = (recaptchaToken: string) => {
		this.sendRequest(recaptchaToken);
	}

	recaptchaOnError = (error: Error, info: any) => {
		this.setStateIfMounted({ 
			...this.state,
			sending: false,
			errorMessage: "Erro ao buscar Captcha",
			renderRecaptcha: false
		});
	 }

	submit = (event: React.FormEvent) => {
		event.preventDefault();
		this.setStateIfMounted({ 
			sendingEmail: false,
			loginBloqueado: false,
			renderRecaptcha: true,
			sending: true, 
			errorMessage: undefined 
		});
	}
	
	sendRequest = (recaptchaToken: string) => {
		// Pega os dados de login
		const login = {
			email: this.email.current.value,
			senha: this.senha.current.value,
			recaptchaToken: recaptchaToken
	   }

		// Chama a API
		getAccessToken(login)
		.then(response => {
			this.setStateIfMounted({ 
				...this.state,
				sending: false, 
				errorMessage: undefined, 
				renderRecaptcha: false 
			});
			
			this.onSuccessfulLogin(response.accessToken, response.roleId); 
		})
		.catch(error => {
			// Se for erro de tentativas de login excedidas, adiciona um link para reenviar e-mail de desbloqueio
			if (error.content && error.content.code===API_ERROR.NUM_MAX_TENTATIVAS_LOGIN_EXCEDIDO) {
				this.setStateIfMounted({ 
					...this.state,
					loginBloqueado: true 
				})
			}

			this.setStateIfMounted({ 
				...this.state,
				renderRecaptcha: false,
				sending: false, 
				errorMessage: (error.content && error.content.message) ? error.content.message : "Erro interno na tentativa de login"
			});
		});
	}

	reenviarEmailDesbloquearLogin = () => {
		this.setStateIfMounted({
			...this.state,
			sendingEmail: true
		});

		let payload = {
			content: this.email.current.value
		}

		reenviarEmailDesbloqueioLogin(payload)
		.then(response => {
			this.setStateIfMounted({
				...this.state,
				successMessage: response.message,
				errorMessage: undefined,
				loginBloqueado: false,
				sendingEmail: false
			});
		})
		.catch(error => {
			this.setStateIfMounted({
				...this.state,
				sendingEmail: false,
				successMessage: undefined,
				errorMessage: (error.content && error.content.message) ? error.content.message : "Erro interno ao enviar e-mail"
			});
		});
	}

	setStateIfMounted = (state: State) => {
		if (this._isMounted)
			this.setState(state);
	}

	setErrors = (errors: any) => {
		this.setStateIfMounted({
			...this.state,
			errorMessage: errors
		})
	}

	redirecionarParaDashboard = (roleId: number | undefined) => {
		// Redireciona para o dashboard ou para a página inicial
		if ((process.env.REACT_APP_ATIVAR_MODULO_DASHBOARD === "true") && roleId) {
			if (roleId === Perfil.ADMINISTRADOR)
				this.props.history.push(getLinkAdmin("dashboard_index"));
			else if (roleId === Perfil.USUARIO)
				this.props.history.push(getLink("dashboard_index", undefined, undefined));
			else
				this.props.history.push("/");
		}
		else {
			this.props.history.push("/");
		}
	}

	onSuccessfulLogin = (accessToken: string, roleId: number | undefined) => {
		if (accessToken) {
			// Salva o token
			localStorage.setItem(ACCESS_TOKEN, accessToken);
			
			// Carrega o usuário para a store
			loadCurrentUser(() => this.redirecionarParaDashboard(roleId));
		}
		else {
			this.setStateIfMounted({ 
				...this.state,
				errorMessage: "Erro ao salvar o token de acesso." 
			});
		}
	}

	render() {
		const { sending, errorMessage, successMessage } = this.state;

		return (
			<div id="login-page" className="page-body-padding form-container-sm">
				<PageTitle>
					Entre com os dados de acesso
				</PageTitle>

				<Form onSubmit={sending ? ()=>{} : this.submit}>
					<Form.Group controlId="label-email">
						<Form.Label>E-mail</Form.Label>
						<Form.Control type="email" placeholder="Login de Acesso" ref={this.email} required />
					</Form.Group>

					<Form.Group controlId="label-senha">
						<span className="text-muted float-right" onClick={this.recuperarSenha} style={{ cursor: 'pointer' }}>
							<small>
								Esqueceu a senha?
							</small>
						</span>
						<Form.Label>Senha</Form.Label>
						<Form.Control type="password" placeholder="Senha de Acesso" ref={this.senha} required />
					</Form.Group>

					{ (this.state.renderRecaptcha && process.env.REACT_APP_CAPTCHA_KEY) ? 
						<ErrorBoundary onError={this.recaptchaOnError} message="Erro interno no ReCaptcha">
							<ReCaptcha
								sitekey={process.env.REACT_APP_CAPTCHA_KEY}
								action='login'
								verifyCallback={this.verifyCaptchaCallback} />
						</ErrorBoundary>
						: null
              }

				  <div className="d-flex pt-2">
						<Button className="btn btn-primary mx-auto" type="submit">
							{ sending ? "Entrando..." : "Entrar" }
						</Button>
					</div>
				</Form>

				{ errorMessage ?
					<ErrorMessages errors={errorMessage}>
						{ this.state.loginBloqueado ?
							<div onClick={this.state.sendingEmail ? (()=>{}) : this.reenviarEmailDesbloquearLogin}><b><br/>Clique aqui para reenviar o e-mail.</b></div>
							: null
						}
					</ErrorMessages>
					: null 
				}

				{ successMessage ?
					<Alert variant="success" className="mt-3">
						{successMessage}
					</Alert>
					: null 
				}

				<div className="text-center mt-4">
					<p className="text-muted font-16">Entrar com</p>
					<ul className="social-list list-inline mt-3 mb-3">
						<li className="list-inline-item">
							{/* <FacebookLoginButton onError={this.setErrors} onSuccessfulLogin={this.onSuccessfulLogin} /> */}
							<Link to="/erro_facebook">
								<span id="facebook-login" 
									className="social-list-item" 
									role="button" 
									tabIndex={0}
									style={{ cursor: 'pointer' }}>
									<i className="mdi mdi-facebook" />
								</span>
							</Link>
						</li>
						<li className="list-inline-item">
							<GoogleLoginButton onError={this.setErrors} onSuccessfulLogin={this.onSuccessfulLogin} />
						</li>
					</ul>
				</div>

				<div className="text-center mt-3 pt-3">
					<p className="text-muted">Não tem uma conta? 
						<Link to="/cadastrarUsuario.xhtml" className="text-muted ml-1">
							<b>
								Cadastre-se
							</b>
						</Link>
					</p>
				</div> 
			</div>
		);
	}
}

export default LoginPage;