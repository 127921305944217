import { USER_LOGOUT, LOAD_USER, LOAD_PARAMS, LOAD_CAMPANHA, CLEAR_CAMPANHA, LOAD_API_INFORMATION, LOAD_MOSTRAR_ALERTA_ATUALIZAR_PAGINA } from "./actions";
import { IUser } from "../../types/user";
import { IParametrosSistema } from "../../types/api";
import { IApiInformation } from "../../core/types/IApiInformation";

export const userLogin = (user: IUser) => ({
	type: LOAD_USER,
	currentUser: user
});

export const userLogout = () => ({
	type: USER_LOGOUT
});

export const loadParameters = (params: IParametrosSistema) => ({
	type: LOAD_PARAMS,
	parametros: params
});

export const loadAlertaAtualizarPagina = (mostrar: boolean) => ({
	type: LOAD_MOSTRAR_ALERTA_ATUALIZAR_PAGINA,
	mostrar
});


export const loadCampanha = (idCampanha: number | undefined, 
	numeroCampanha: string | undefined, 
	email: string | undefined,
	idStatus: number | undefined,
	qtde: number | undefined,
	valorLiquido: number | undefined,
	arquivoComprovanteNome: string | undefined) => 
({
	type: LOAD_CAMPANHA,
	idCampanha,
	numeroCampanha,
	email,
	idStatus,
	qtde,
	valorLiquido,
	arquivoComprovanteNome
});

export const clearCampanha = () => ({
	type: CLEAR_CAMPANHA
});

export const loadApiInformation = (info: IApiInformation) => ({
	type: LOAD_API_INFORMATION,
	apiInformation: info
});