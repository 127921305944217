import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import { RifaStatus } from '../../constants';
import { getLink } from '../../utils/FunctionUtils';

interface ParentProps {
	show?: boolean
}

type Props = ParentProps & ReturnType<typeof mapStateToProps>

const MenuUsuario: React.FC<Props> = (props) => {
	const {
		show,
		campanhaSelecionada,
		user
	} = props;

	if (!show)
		return null;

	let usuarioLogadoEhDonoDaRifa:boolean = (campanhaSelecionada && 
				user.currentUser && 
				(user.currentUser.email === campanhaSelecionada.email)) ? 
		true
		: false;

	return (
		<div className={"left-side-menu" + (props.mostrarAlertaAtualizarPagina ? " mostrar-alerta-global" : "")}>
			<Link to="/" className="logo text-center logo-light">
				<span className="logo-lg">
					<img src={process.env.PUBLIC_URL + "/images/logo-dashboard.png"} alt="Logo" height="50" />
				</span>
			</Link>
			
			<div className="h-100" id="left-side-menu-container">
				<ul className="metismenu side-nav">
					{ (campanhaSelecionada && campanhaSelecionada.numeroCampanha && campanhaSelecionada.idCampanha) ?
						<React.Fragment>
							<li className="side-nav-title side-nav-item">Campanha {campanhaSelecionada.numeroCampanha}</li>
							{ campanhaSelecionada.idCampanha ? 
								<li className="side-nav-item">
									<Link to={getLink("visualizar_campanha", campanhaSelecionada.idCampanha, campanhaSelecionada.numeroCampanha)} className="side-nav-link">
										<i className="uil-search-alt"></i>
										<span>Visualizar</span>
									</Link>
								</li>
								: null 
							}

							{ (campanhaSelecionada.idCampanha && (campanhaSelecionada.idStatus === RifaStatus.APROVADO) && usuarioLogadoEhDonoDaRifa) ?
								<li className="side-nav-item">
									<Link to={getLink("editar_campanha", campanhaSelecionada.idCampanha, campanhaSelecionada.numeroCampanha)} className="side-nav-link">
										<i className="uil-pen"></i>
										<span>Editar</span>
									</Link>
								</li>
								: null
							}

							{ (campanhaSelecionada.numeroCampanha && (campanhaSelecionada.qtde || campanhaSelecionada.valorLiquido)) ?
								<li className="side-nav-item">
									<Link to={getLink("ver_bilhetes", campanhaSelecionada.idCampanha, campanhaSelecionada.numeroCampanha)} className="side-nav-link">
										<i className="uil-ticket"></i>
										<span>
											{ (process.env.REACT_APP_NOME_SISTEMA === "Dhoe")
												? "Doações"
												: "Bilhetes"
											}
										</span>
									</Link>
								</li>
								: null
							}

							{ (campanhaSelecionada.numeroCampanha) ?
								<li className="side-nav-item">
									<Link to={getLink("analytics", campanhaSelecionada.idCampanha, campanhaSelecionada.numeroCampanha)} className="side-nav-link">
										<i className="uil-analytics"></i>
										<span>Analytics</span>
									</Link>
								</li>
								: null
							}

							{ (campanhaSelecionada.arquivoComprovanteNome && usuarioLogadoEhDonoDaRifa) ?
								<li className="side-nav-item">
									<Link to={getLink("comprovante_pagamento", campanhaSelecionada.idCampanha, campanhaSelecionada.numeroCampanha)} className="side-nav-link">
										<i className="uil-file-alt"></i>
										<span>Comprovante de Pagamento</span>
									</Link>
								</li>
								: null
							}

							{ (campanhaSelecionada.numeroCampanha && usuarioLogadoEhDonoDaRifa) ?
								<li className="side-nav-item">
									<Link to={getLink("liberar_acesso", campanhaSelecionada.idCampanha, campanhaSelecionada.numeroCampanha)} className="side-nav-link">
										<i className="uil-share-alt"></i>
										<span>Liberar Acesso</span>
									</Link>
								</li>
								: null
							}

							{ (campanhaSelecionada.numeroCampanha && usuarioLogadoEhDonoDaRifa && (campanhaSelecionada.idStatus === RifaStatus.APROVADO)) ?
								<li className="side-nav-item">
									<Link to={`/dashboard/afiliacao/incluir/${campanhaSelecionada.numeroCampanha}`} className="side-nav-link">
										<i className="uil-bag"></i>
										<span>Criar Affiliate Link</span>
									</Link>
								</li>
								: null
							}
						</React.Fragment>
						: null
					}

					<li className="side-nav-title side-nav-item">Navegação</li>
					<li className="side-nav-item">
						<Link to={getLink("listar_campanhas", undefined, undefined)}  className="side-nav-link">
							<i className="uil-list-ul"></i>
							<span>Campanhas</span>
						</Link>
						<Link to={getLink("criar_campanha", undefined, undefined)} className="side-nav-link">
							<i className="uil-plus"></i>
							<span>Criar Campanha</span>
						</Link>
						<Link to={getLink("gerenciar_acessos", undefined, undefined)}  className="side-nav-link">
							<i className="uil-users-alt"></i>
							<span>Gerenciar Acessos</span>
						</Link>
						<Link to="/dashboard/afiliacoes"  className="side-nav-link">
							<i className="uil-chart-pie-alt"></i>
							<span>Ver Afiliações</span>
						</Link>
						<Link to={getLink("alterar_senha", undefined, undefined)}  className="side-nav-link">
							<i className="uil-padlock"></i>
							<span>Alterar Senha</span>
						</Link>
						<Link to="/logout" className="side-nav-link">
							<i className="uil-exit"></i>
							<span>Sair</span>
						</Link>
						<a href={process.env.REACT_APP_CONTENT_URL} className="side-nav-link">
							<i className="uil-home-alt"></i>
							<span>Voltar ao Site</span>
						</a>
					</li>
				</ul>
			</div>

		</div>
	);
}

const mapStateToProps = (store: ApplicationState) => ({
	campanhaSelecionada: store.campanhaSelecionada,
	user: store.userData,
	mostrarAlertaAtualizarPagina: store.parametros.mostrarAlertaAtualizarPagina
});

export default connect(mapStateToProps)(MenuUsuario);