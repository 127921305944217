/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import GoogleLogin from 'react-google-login';
import { loginGoogle } from '../api/Login';

interface State {
	sending: boolean
}

interface Props {
	onSuccessfulLogin: (accessToken: string, roleId: number | undefined) => void,
	onError: (error: any) => void,
	text?: string
}

class GoogleLoginButton extends React.Component<Props, State> {
	responseGoogle = (response: any) => {
		const {
			onSuccessfulLogin,
			onError
		} = this.props;
		const { tokenId } = response;
		
		if (!tokenId) {
			if (typeof onError === 'function')
				onError("Erro ao obter token do Google");
		}

		const payload = {
			content: tokenId
		}

		loginGoogle(payload)
		.then(res => {
			if (typeof onSuccessfulLogin === 'function')
				onSuccessfulLogin(res.accessToken, res.roleId);
		})
		.catch(error => {
			if (typeof onError === 'function')
				onError(error.content ? error.content : "Erro ao fazer login com o Google");
		});
	}

	render() {
		const {
			text,
			onError
		} = this.props;

		return (
			<GoogleLogin
				clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENTID || ''}
				buttonText={text ?? "Entrar com Google" }
				onSuccess={this.responseGoogle}
				onFailure={onError}
				cookiePolicy='single_host_origin'
				responseType="id_token"
				render={(renderProps) => (
					<span id="google-login" 
							className="social-list-item" 
							onClick={renderProps.disabled ? ()=>{} : renderProps.onClick} 
							style={{ cursor: 'pointer', backgroundColor: '#fff' }}
							role="button"
							tabIndex={0} >
						<i className="mdi mdi-google" />
					</span>
				)}
			/>
		);
	}
}

export default GoogleLoginButton;