import React from 'react';
import Alert from 'react-bootstrap/Alert';

interface Props {
	onError?: (error: Error, info: any) => void,
	message?: string
}

interface State {
	hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error: Error, info: any): void {
		const { onError } = this.props;

		this.setState({ hasError: true });

		if (typeof onError === 'function')
			onError(error, info);
	}

	render() {
		const { message, children } = this.props;
		const { hasError } = this.state;

		if (hasError) {
			return <Alert variant="danger" className="my-2"> { message ?? "Erro interno"} </Alert>;
		}
		return children;
	}
}

export default ErrorBoundary;