import { ACCESS_TOKEN, API_ERROR } from "../constants";
import { ILogin } from "../types/user";
import { IParametrosSistema } from "../types/api";
import { IEntregaPremio } from "../types/rifa";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface APIRequest {
    path?: string,
    url?: string,
    method: string,
    body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream | null | undefined
}

export const request = (req: APIRequest): Promise<any> => {
    if (!req.url && !req.path)
        throw new Error("A url e path não podem ser ambos undefined.");

    if (req.path && req.path[0] !== "/")
        throw new Error("O path deve começar com /");
    
    const headers = new Headers({
        'Content-Type': 'application/json; charset=utf-8'
    })

    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`)
    }

    const options = { 
        headers, 
        ...req
    };
    
    const url = options.url ?? `${API_BASE_URL}${options.path}`

    return fetch(url, options)
    .then(response =>
        response.json().then(json => {
            if(!response.ok) {
                if (json.code === API_ERROR.TOKEN_INVALIDO || json.code === API_ERROR.UNAUTHORIZED || json.code === API_ERROR.FORBIDDEN)
                    window.location.href = `${process.env.PUBLIC_URL}/login.xhtml`;
                return Promise.reject({status : response.status, content: json});
            }
            return json;
        })
    );
};

export function getAccessToken(login: ILogin) {
    return request({
        path: "/login",
        method: 'POST',
        body: JSON.stringify(login)
    });
}

export function entregaPremio(payload: IEntregaPremio) {
    return request({
        path: "/premio/entrega",
        method: 'POST',
        body: JSON.stringify(payload)
    });
}

export function getParametros() {
    return request({
        path: `/parametros`,
        method: 'GET'
    });
}

export function getBilhetesRifa(page: number, size: number, numeroRifa: string, sortField: string, sortOrder: string) {
    return request({
        path: `/bilhetes/campanha/${numeroRifa}?page=${page}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}`,
        method: 'GET'
    });
}

export function cadastrarTarifa(payload: any) {
    return request({
        path: "/tarifa",
        method: 'POST',
        body: JSON.stringify(payload)
    });
}

export function deletarTarifa(id: number) {
    return request({
        path: `/tarifa/${id}`,
        method: 'DELETE'
    });
}

export function getParametrosAdmin() {
    return request({
        path: "/parametros/admin",
        method: 'GET'
    });
}

export function getVersaoFrontend() {
    return request({
        path: "/parametros/version",
        method: 'GET'
    });
}

export function updateParametrosAdmin(payload: IParametrosSistema) {
    return request({
        path: "/parametros/admin",
        method: 'PUT',
        body: JSON.stringify(payload)
    });
}

export function reenviarNotificacaoGanhador(id: number, payload: any) {
    return request({
        path: `/notificacao/ganhador/reenviar/${id}`,
        method: 'POST',
        body: JSON.stringify(payload)
    });
}
