import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
	code?: string,
	message?: string
}

const ErrorPage: React.FC<Props> = (props) => {
	return (
		<div className="banner-container bg-primary text-center" style={{minHeight: "80vh"}}>
            <div className="page-body-padding">
                <span id="error-code" className="title-common title-secondary">
                    {props.code ? props.code : ""}
                </span>
                <h4 id="error-message" className="title-sm-common title-secondary">
                    {props.message ? props.message : "Esta página não existe."} 
                </h4>

                <Link className="btn btn-secondary mt-4" to="/">
                    Voltar à página inicial
                </Link>
            </div>
        </div>
	);
}

export default ErrorPage;
