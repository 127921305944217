export const ACCESS_TOKEN = 'ACCESS_TOKEN';

export const currencyFormat = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };

export const QTD_MAX_BILHETES = 30; // quantidade máxima de bilhetes por compra
export const VALOR_MAXIMO_COMPRA = 300;

export enum RifaStatus {
    AGUARDANDO_APROVACAO = 1,
    APROVADO = 2,
    REPROVADO = 3,
    AGUARDANDO_CONFIRMACAO_EMAIL = 4,
    PAUSADA = 5,
    CANCELADA = 6,
    AGUARDANDO_SORTEIO = 7,
    AGUARDANDO_ENTREGA_PREMIO = 8,
    AGUARDANDO_PAGAMENTO = 9,
    FINALIZADA = 10,
    SORTEADA = 11,
    ARRECADACAO_ENCERRADA = SORTEADA,
    LIBERADO_PARA_PAGAMENTO = 12,
    EM_ANALISE = 13,
    META_NAO_ATINGIDA = 14,
    PREMIO_RECEBIDO = 15,
    DEMANDA_ESTORNO_MANUAL = 16
}

export const estados = ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO"];

export enum SortField {
    NUMERO_RIFA = "numeroRifa",
    NOME_ORGANIZADOR = "nomeCompleto",
    NOME_BENEFICIARIO = "nomeBeneficiario",
    STATUS = "status.descricao",
    DATA_ENCERRAMENTO = "dataEncerramento",
    QUANTIDADE = "qtde",
    VALOR_UNITARIO = "valorUnitario",
    VALOR_TOTAL = "valorTotal",
    VALOR_TOTAL_BRUTO = "valorTotalBruto",
    VALOR_TOTAL_LIQUIDO = "valorTotalLiquido"
}


export enum SortFieldBilhete {
    NUMERO_BILHETE = "numeroBilhete",
    EMAIL = "email",
    NOME = "nome",
    TELEFONE = "telefone",
    DATA_COMPRA = "data",
    CODIGO_AFILIACAO = "codigoAfiliacao"
}

// se a largura da janela for menor ou igual a esse valor, o boostrap considera que o dispositivo é mobile
export const BOOTSTRAP_MOBILE_BREAKPOINT = 991; 

export enum API_ERROR {
    UNAUTHORIZED = 401, 
    FORBIDDEN = 403,
    TOKEN_INVALIDO = 8856,
    NUM_MAX_TENTATIVAS_LOGIN_EXCEDIDO = 8903
}

export enum SISTEMA_TARIFA {
    SISTEMA_TARIFA_COMPOSTO = 1,
    SISTEMA_TARIFA_UNICO = 2
}

export enum Parceiro {
    CIELO = 1,
    PAGSEGURO = 2,
    STRIPE = 3,
    TRANSFEERA = 4
}

export enum TipoFormaPagamento {
    BOLETO = 1001,
    CARTAO_CREDITO_BRASIL = 1002,
    CARTAO_CREDITO_EXTERIOR = 1003,
    PIX = 1004
}