import { createStore } from 'redux';
import Reducers from '../reducer';
import { CurrentUserState, ParametrosState, CampanhaSelecionadaState } from '../../types/redux';
import { IApiInformation } from '../../core/types/IApiInformation';

export interface ApplicationState {
   userData: CurrentUserState,
   parametros: ParametrosState,
   campanhaSelecionada: CampanhaSelecionadaState,
   apiInformation: IApiInformation
}

export const Store = createStore(Reducers);