import React from 'react';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './DashboardNav.scss';
import { BOOTSTRAP_MOBILE_BREAKPOINT } from '../../constants';
import { ApplicationState } from '../../redux/store';

interface Props {
	children?: React.ReactNode,
	linkVoltar?: string,
	hideRightButtons?: boolean,
	mostrarAlertaAtualizarPagina: boolean | undefined
}

interface State {
	isMobileWidth: boolean
}

class DashboardNavLeft extends React.Component<any, any> {
	render () {
		if (this.props.ismobilewidth === "true") {
			return (
				<Col xs={12} lg={this.props.md ? this.props.md : 7}>
					{this.props.children}                       
				</Col>
			);
		}

		return (
			<Col xs={12} lg={this.props.md ? this.props.md : 7}>
				<ul className="list-unstyled topbar-right-menu float-left mb-0 w-100">
					{this.props.children}                       
				</ul>
			</Col>
		);
	}
}

class DashboardNavRight extends React.Component<any, any> {
	render () {
		if (this.props.ismobilewidth === "true") {
			return (
				<Col xs={12} lg={this.props.md ? this.props.md : 5}>
					{this.props.children}                       

					{ !this.props.hiderightbuttons &&
						<Link to={this.props.linkVoltar ? this.props.linkVoltar : "/"}>
							<Button size="sm" className="w-100">
								Voltar
							</Button>
						</Link>
					}

					{ !this.props.hiderightbuttons &&
						<Link to="/logout">
							<Button size="sm" className="w-100">
								Sair
							</Button>
						</Link>
					}
				</Col>
			);
		}

		return (
			<Col xs={12} lg={this.props.md ? this.props.md : 5} style={{ justifyContent: 'flex-end' }}>
				<ul className="list-unstyled topbar-right-menu float-right mb-0">
					{this.props.children}   

					{ !this.props.hiderightbuttons &&
						<Link to={this.props.linkVoltar ? this.props.linkVoltar : "/"}>
							<Button size="sm">
								Voltar
							</Button>
						</Link>
					}

					{ !this.props.hiderightbuttons &&
						<Link to="/logout">
							<Button size="sm">
								Sair
							</Button>
						</Link>
					}                    
				</ul>
			</Col>
		);
	}
}

// Barra de navegação superior
class DashboardNav extends React.Component<Props, State> {
	static Left = DashboardNavLeft;
	static Right = DashboardNavRight;

	constructor(props: Props) {
		super(props);

		this.state = {
			isMobileWidth: (window.innerWidth <= BOOTSTRAP_MOBILE_BREAKPOINT)
		}
	}

	componentDidMount() {
		window.addEventListener("resize", this.resizeWindow);
	}

  componentWillUnmount() {
		window.removeEventListener("resize", this.resizeWindow);
  }

  resizeWindow = () => {
	  const isMobileWidth = (window.innerWidth <= BOOTSTRAP_MOBILE_BREAKPOINT);
		this.setState({ 
			isMobileWidth: isMobileWidth
		});
  	}

	render () {
		// Passa props para os filhos
		let children = [];
		if (this.props.children) {
			let elements = React.Children.toArray(this.props.children);
			for (let i in elements) {
				if (React.isValidElement(elements[i])) {
					let child = React.cloneElement(elements[i] as React.ReactElement<any>, { 
						hiderightbuttons: this.props.hideRightButtons, 
						linkboltar: this.props.linkVoltar,
						ismobilewidth: this.state.isMobileWidth ? "true" : "false"
					})
					children.push(child);
				}
			}
		}

		// Barra de navegação para mobile
		if (this.state.isMobileWidth) {
			return (
				<div id="dashboard-nav" className="d-block">
					<div id="navbar-admin-mobile">
						<div id="basic-navbar-nav">
							<div className="mt-3"> 
								<Row className="ml-0 mr-0">   
									{children}   
								</Row>                    
							</div>
						</div>
					</div>
				</div>
			);
		}

		// Barra de navegação para desktop
		return (
			<div className={"navbar-custom d-sm-nome d-md-block" + (this.props.mostrarAlertaAtualizarPagina ? " mostrar-alerta-global" : "") } id="dashboard-nav">
				<Row className="ml-0 mr-0 w-100">
					{children}                       
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (store: ApplicationState) => ({
	mostrarAlertaAtualizarPagina: store.parametros.mostrarAlertaAtualizarPagina
});

export default connect(mapStateToProps)(DashboardNav);