import { QTD_MAX_BILHETES } from "../constants";

export function validarComprarGrupo(comprarGrupo: number | undefined): string | undefined {
	if (!comprarGrupo)
		return 'Selecione a quantidade de bilhetes.';
	
	if (comprarGrupo > QTD_MAX_BILHETES)
		return `Você pode comprar no máximo ${QTD_MAX_BILHETES} bilhetes de cada vez.`;

	return undefined;
}