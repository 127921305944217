import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { CurrentUserState } from '../types/redux';
import { ApplicationState } from '../redux/store';
import { Perfil } from '../types/user';
import LoginPageComplete from '../pages/login/LoginPageComplete';
import NotFoundPageComplete from '../pages/error/NotFoundPageComplete';

interface ReduxProps {
   user: CurrentUserState
}

interface ParentProps {
	perfilUsuario: number, // tipo de usuário que a página deve aceitar
	children?: JSX.Element | JSX.Element[] | null
}

type Props = RouteComponentProps & ReduxProps & ParentProps;

const ProtectedPage: React.FC<Props> = (props: Props) => {
	const { user, perfilUsuario, children } = props;
	const { isAuthenticated, currentUser } = user;

	if (!isAuthenticated || !currentUser) {
		// Impede que usuários não logados tenham acesso às páginas de administração
		if (props.perfilUsuario === Perfil.ADMINISTRADOR) 
			return <><NotFoundPageComplete /></>;

		return <><LoginPageComplete {...props} /></>
	}

	// Impede que usuários comuns tenham acesso às páginas de administração
	if (perfilUsuario === Perfil.ADMINISTRADOR &&
		 currentUser.perfilId === Perfil.USUARIO) {
		return <NotFoundPageComplete />
	}
	 
   return <>{children ?? null}</>;
}

const mapStateToProps = (store: ApplicationState) => ({
   user: store.userData
});

export default connect(mapStateToProps)(ProtectedPage);