import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Perfil } from '../types/user';
import ProtectedPage from './ProtectedPage';

interface ParentProps {
    children?: any
}

type Props = RouteComponentProps & ParentProps;

const ProtectedPageUser: React.FC<Props> = (props) => {
    return <ProtectedPage perfilUsuario={Perfil.USUARIO} {...props} />
}

export default ProtectedPageUser;