import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApiInformationController } from '../../core/controller/ApiInformationController';
import { IApiInformation } from '../../core/types/IApiInformation';
import { ApplicationState } from '../../redux/store';
import { getLinkAdmin } from '../../utils/FunctionUtils';

interface Props {
	show?: boolean,
	apiInformation?: IApiInformation,
	mostrarAlertaAtualizarPagina: boolean | undefined
}

const MenuAdministrador: React.FC<Props> = ({ show, apiInformation, mostrarAlertaAtualizarPagina }: Props) => {

	useEffect(() => {
		ApiInformationController.getInstance().initializeApiInformation();
	}, []);

	if (!show)
		return null;

	return (
		<div className={"left-side-menu"  + (mostrarAlertaAtualizarPagina ? " mostrar-alerta-global" : "")} style={{ overflowY: "hidden" }}>
			<Link to="/" className="logo text-center logo-light">
				<span className="logo-lg">
					<img src={process.env.PUBLIC_URL + "/images/logo-dashboard.png"} alt="Logo" height="50" />
				</span>
			</Link>
			
			<div className="h-100" id="left-side-menu-container">
				<ul className="metismenu side-nav">
					<li className="side-nav-title side-nav-item">Navegação</li>
					<li className="side-nav-item">
						<Link to={getLinkAdmin("gerenciamento_campanhas")}  className="side-nav-link">
							<i className="uil-list-ul"></i>
							<span>Gerenciar Campanhas</span>
						</Link>
						<Link to={getLinkAdmin("gerenciamento_usuarios")} className="side-nav-link">
							<i className="uil-users-alt"></i>
							<span>Gerenciar Admin</span>
						</Link>
						<Link to={getLinkAdmin("alterar_senha_interno")}  className="side-nav-link">
							<i className="uil-padlock"></i>
							<span>Alterar Senha</span>
						</Link>
						<Link to={getLinkAdmin("servidor_email")}  className="side-nav-link">
							<i className="uil-location-arrow"></i>
							<span>Servidor de E-mail</span>
						</Link>
						<Link to={getLinkAdmin("parametros_sistema")}  className="side-nav-link">
							<i className="uil-cog"></i>
							<span>Parâmetros do Sistema</span>
						</Link>
						<Link to={getLinkAdmin("emails_marketing")}  className="side-nav-link">
							<i className="uil-file-alt"></i>
							<span>E-mails para Marketing</span>
						</Link>
						<a href={`${process.env.REACT_APP_CONTENT_URL}/wp-admin/`} className="side-nav-link">
							<i className="uil-newspaper"></i>
							<span>Conteúdo</span>
						</a>
						<Link to="/logout" className="side-nav-link">
							<i className="uil-exit"></i>
							<span>Sair</span>
						</Link>
					</li>
				</ul>
			</div>

			{/* Versões dos sistemas */}
			<div className="version-container d-none d-lg-block">
				<div>
					<a href={`${process.env.REACT_APP_LINK_GIT_RELEASES_WEB}/v${process.env.REACT_APP_VERSION}`} target="_blank" rel="noopener noreferrer">
						{`v${process.env.REACT_APP_VERSION} web`}
					</a>
				</div>
				{ apiInformation?.version
					? <a href={`${process.env.REACT_APP_LINK_GIT_RELEASES_SERVIDOR}/v${apiInformation.version}`} target="_blank" rel="noopener noreferrer">
						{`v${apiInformation.version} servidor`}
					</a>
					: null
				}
			</div>
		</div>
	);
}

const mapStateToProps = (store: ApplicationState) => ({
	apiInformation: store.apiInformation,
	mostrarAlertaAtualizarPagina: store.parametros.mostrarAlertaAtualizarPagina
});

export default connect(mapStateToProps)(MenuAdministrador);