export interface ILogin {
	email: string, 
	senha: string
}

export interface IUser {
	bairro: string,
	celular: string,
	cep: string,
	cidade: string,
	complemento: string,
	concordaTermoUso: boolean,
	cpf: string,
	dataCadastro: string,
	dataNasc: string,
	email: string,
	estado: string,
	logradouro: string,
	nome: string,
	numero: string,
	perfilId: number,
	sexo: string,
	status: string,
	uf: string,
	usuarioId: number,
	perfilDesc: string,
	ipCadastro: string
}

export enum Perfil {
	ADMINISTRADOR=1,
	USUARIO=2
}