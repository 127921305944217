import moment from 'moment-timezone';
import { ACCESS_TOKEN, RifaStatus } from "../constants";
import { getParametros } from "./APIUtils";
import { Store } from "../redux/store";
import { LOAD_USER, LOAD_PARAMS, LOAD_CAMPANHA, CLEAR_CAMPANHA } from "../redux/action/actions";
import { getCurrentUser } from "../core/api/Usuario";

// Carrega os dados do usuario para a store
export function loadCurrentUser(onSuccess?: () => void): void {
	const token = localStorage.getItem(ACCESS_TOKEN);

	if (!token)
		return;

	getCurrentUser()
	.then(response => {
		Store.dispatch({type: LOAD_USER, currentUser: response})

		if (typeof onSuccess === 'function')
			onSuccess();
	})
	.catch(error => {
		localStorage.removeItem(ACCESS_TOKEN);
	}); 
}

export function loadSystemParameters(): void {
	getParametros()
	.then(response => {
		Store.dispatch({type: LOAD_PARAMS, parametros: response})
	})
	.catch(error => {
		console.error("Erro ao carregar parâmetros do sistema");
	}); 
}

export function selecionarCampanha(idCampanha: number | undefined, 
		numeroCampanha: string | undefined, 
		email: string | undefined,
		idStatus: number | undefined,
		qtde: number | undefined,
		valorLiquido: number | undefined,
		arquivoComprovanteNome: string | undefined): void
{
	Store.dispatch({ 
		type: LOAD_CAMPANHA, 
		idCampanha, 
		numeroCampanha,
		email,
		idStatus,
		qtde,
		valorLiquido,
		arquivoComprovanteNome
	})
}

export function deselecionarCampanha(): void {
	Store.dispatch({type: CLEAR_CAMPANHA })
}

export function getLink(acao: string, idRifa: string | number | undefined, numeroRifa: string | undefined): string {
	if (acao === 'visualizar_campanha')
		return `/dashboard/campanha/ver/${idRifa}`;
	if (acao === 'editar_campanha')
		return `/dashboard/campanha/editar/${idRifa}`;
	if (acao === 'ver_bilhetes')
		return `/dashboard/campanha/bilhetes/${numeroRifa}`;
	if (acao === 'comprovante_pagamento')
		return `/dashboard/campanha/comprovante/${numeroRifa}`;
	if (acao === 'liberar_acesso')
		return `/dashboard/liberar_acesso/${numeroRifa}`;
	if (acao === 'analytics')
		return `/dashboard/campanha/analytics/${numeroRifa}`;
	if (acao === 'criar_campanha')
		return `/dashboard/campanha/criar`;
	if (acao === 'listar_campanhas')
		return `/dashboard/campanhas`;
	if (acao === 'gerenciar_acessos')
		return `/dashboard/acessos`;
	if (acao === 'alterar_senha')
		return `/dashboard/recuperar_senha`;
	if (acao === 'dashboard_admin')
		return `/admin`;
	if (acao === 'dashboard_index')
		return `/dashboard`;

	return "/";
}

export function getLinkAdmin(acao: string, idCampanha?: number | string | undefined): string {
	if (acao === 'gerenciamento_campanhas')
		return `/admin/gerenciamentoRifas.xhtml`;
	if (acao === 'alterar_senha_interno')
		return '/admin/alterarSenhaInterno.xhtml';
	if (acao === 'servidor_email')
		return '/admin/servidorEmail.xhtml';
	if (acao === 'gerenciamento_usuarios')
		return '/admin/gerenciamentoUsuarios.xhtml';
	if (acao === 'relatorio_usuarios')
		return '/admin/relatorio_usuarios.xhtml';
	if (acao === 'cadastrar_tarifas')
		return '/admin/cadastrar_tarifas.xhtml';
	if (acao === 'parametros_sistema')
		return '/admin/parametrosSistema.xhtml';
	if (acao === 'aprovar_campanha')
		return `/admin/aprovarCampanha/${idCampanha}`;
	if (acao === 'emails_marketing')
		return `/admin/emailsMarketing`;
	if (acao === 'dashboard_index')
		return `/admin`;

	return "/";
}

export function isRifaBloqueada(status: number): boolean {
	return (status === RifaStatus.REPROVADO
		|| status === RifaStatus.CANCELADA
		|| status === RifaStatus.PAUSADA
		|| status === RifaStatus.AGUARDANDO_APROVACAO
		|| status === RifaStatus.AGUARDANDO_CONFIRMACAO_EMAIL
		|| status === RifaStatus.EM_ANALISE
		|| status === RifaStatus.META_NAO_ATINGIDA);
}

export function isRifaStatusInvalido(status: number): boolean {
	return (status === RifaStatus.AGUARDANDO_APROVACAO 
		|| status === RifaStatus.REPROVADO
		|| status === RifaStatus.AGUARDANDO_CONFIRMACAO_EMAIL
		|| status === RifaStatus.PAUSADA
		|| status === RifaStatus.CANCELADA
		|| status === RifaStatus.EM_ANALISE
		|| status === RifaStatus.META_NAO_ATINGIDA);
}

export function gerarLinkReduzido(numeroRifa: string): string {
	// Retira zeros à esquerda
	let numeroAux = "";
	for (let i = 0; i < numeroRifa.length; i++) {
		if (numeroRifa[i] !== '0') {
			numeroAux += numeroRifa.substring(i);
			break;
		}
	}

	// Remove o subdomínio da url se ele existir
	let url = process.env.PUBLIC_URL;
	if (url.match(/^https?:\/\/[:\w.]+\/\w+$/)) {
		const arr = url.split('/');
		arr.pop();
		url = arr.join('/');
	}

	return `${url}/${numeroAux}.xhtml`;
}

/**
 * Obtém a descrição de um status da rifa a partir do id do status
 * 
 * @param statusId id do status
 * @return string contendo a descrição do status ou string vazia caso o status não seja válido
 */
export function getDescricaoRifaStatus(statusId: number): string {
	switch(statusId) {
		case RifaStatus.AGUARDANDO_APROVACAO:
			return "Aguardando Aprovação";
		case RifaStatus.APROVADO:
			return "Aprovada";
		case RifaStatus.REPROVADO:
			return "Reprovada";
		case RifaStatus.AGUARDANDO_CONFIRMACAO_EMAIL:
			return "Aguardando Confirmação de E-mail";
		case RifaStatus.PAUSADA:
			return "Pausada";
		case RifaStatus.CANCELADA:
			return "Cancelada";
		case RifaStatus.AGUARDANDO_SORTEIO:
			return "Aguardando Sorteio";
		case RifaStatus.AGUARDANDO_ENTREGA_PREMIO:
			return "Aguardando Entrega do Prêmio";
		case RifaStatus.AGUARDANDO_PAGAMENTO:
			return "Aguardando Pagamento";
		case RifaStatus.FINALIZADA:
			return "Finalizada";
		case RifaStatus.SORTEADA:
			return "Sorteada";
		case RifaStatus.LIBERADO_PARA_PAGAMENTO:
			return "Liberado para Pagamento";
		case RifaStatus.EM_ANALISE:
			return "Disputa Aberta";
		case RifaStatus.META_NAO_ATINGIDA:
			return "Meta não atingida";
		case RifaStatus.PREMIO_RECEBIDO:
			return "Prêmio Recebido";
		default:
			return "";
	}
}

// Calcula x mod n de modo que o número retornado seja sempre positivo
export const mod = (x: number, n: number): number => (x % n + n) % n

/** Calcula fator de redimensionamento para ajustar uma imagem que já foi rotacionada.
 * A função assume que o fator só deve ser calculado quando a imagem for rotacionada
 * por -90 ou 90 graus
 * 
 * @param width largura da imagem antes de ser redimensionada/rotacionada em pixels
 * @param height altura da imagem antes de ser redimensionada/rotacionada em pixels
 * @param maxWidth largura máxima que a imagem pode assumir em pixels
 * @param maxHeight altura máxima que a imagem pode assumir em pixels
 */
export function calculateScaleRotatedImage(width: number, height: number, maxWidth: number, maxHeight: number): number {
	if (height===width) 
		return 1;

	// Calcula as dimensões da imagem após ela ser redimensionada
	// de acordo com a altura maxHeight (redimensionamento automático feito pelo css)
	// Ao girar a imagem, a altura vira a largura e vice-versa
	let newHeight = width;
	let newWidth = height;
	if (height > maxHeight) {
		const r = maxHeight / height;
		newWidth = maxHeight;
		newHeight = width * r;
	}

	/* Ao girar a imagem, é necessário fazer outro redimensionamento para evitar que ela ultrapasse
		* as bordas do div pai. Ao girar, a nova altura da imagem será newHeight. Então, o valor de 
		* newHeight deve ser redimensionado para maxHeight. O código abaixo calcula esse 
		* fator de redimensionamento.
		*/
	let rate = maxHeight/newHeight;
	const widthAfterResize = newWidth*rate;
	
	// Se a largura após o ajuste (widthAfterResize) for maior que a largura máxima, 
	// a imagem será cortada. Então, é necessário fazer um novo ajuste
	if (widthAfterResize > maxWidth) {
		rate = maxWidth / newWidth;
	}

	return rate;
}

// Pega uma string de data no formato yyyy-MM-dd com ou sem informação de horário
// e transforma em uma string do tipo dd/MM/yyyy no horário do Brasil
export function convertStringDate(dateStr: string | number | null | undefined): string {
	if (!dateStr)
		return "";

	// Verifica se a data é um timestamp
	if (typeof dateStr === 'number') {
		return moment(new Date(dateStr).toISOString(), 'YYYY-MM-DD HH:mm:ssZ', 'America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss');
	}

	return moment(dateStr, 'YYYY-MM-DD', 'America/Sao_Paulo').hour(0).format('DD/MM/YYYY');
}

export function convertDataSorteio(dateStr: string | null | undefined): string {
	if (!dateStr)
		return "";

	return moment(dateStr, 'YYYY-MM-DD HH:mm', 'America/Sao_Paulo').format('DD/MM/YYYY HH:mm');
}

export function chegouDataSorteio(dateStr: string | null | undefined): boolean {
	if (!dateStr)
		return false;

	const dataEncerramento = moment.tz(dateStr, "", 'America/Sao_Paulo');
	const now = moment().tz('America/Sao_Paulo');

	return now.isSameOrAfter(dataEncerramento);
}

export function formatarCPFouCNPJ(cpfoucnpj: string): string {
	let valorFormatado = cpfoucnpj.replace(/\D/g, ""); // remove caracteres que não sejam dígitos
	 
	// O maior comprimento possível é para o CNPJ: 14 dígitos
	if (valorFormatado.length > 14)
		valorFormatado = valorFormatado.substring(0,14);

	if (valorFormatado.length <= 11) {
		const matches = valorFormatado.match(/(\d{0,3})?(\d{0,3})?(\d{0,3})?(\d{0,2})?/);

		/**
		 * Quando existe um grupo opcional no regex - ex: (\d)?, a função match retorna undefined se ele não existe
		 * na posição equivalente a posição que ele tem na expressão regular
		 * 
		 * String de exemplo: 123456789
		 * Regex: /(\d{0,3})?(\d{0,3})?(\d{0,3})?(\d{0,2})?/
		 * 
		 * matches[0] = "123456789", a string toda
		 * matches[1] = "123", os dígitos de posição 0 a 2 (primeiro grupo)
		 * matches[2] = "456", os dígitos de posição 3 a 5 (segundo grupo)
		 * matches[3] = "789", os dígitos de posição 6 a 8 (terceiro grupo)
		 * matches[4] = undefined, os dígitos de posição 9 e 10 (quarto grupo)
		 */
		if (matches && matches[1]){
			matches.shift(); // remove primeiro elemento do array (string toda)
			const [ grupo1, grupo2, grupo3, grupo4 ] = matches;
			valorFormatado = grupo1;

			if (grupo2) {
				valorFormatado += `.${grupo2}`;

				if (grupo3) {
					valorFormatado += `.${grupo3}`;

					if (grupo4) {
						valorFormatado += `-${grupo4}`;
					}
				}
			}
		}
	} 
	else {
		const matches = valorFormatado.match(/(\d{0,2})?(\d{0,3})?(\d{0,3})?(\d{0,4})?(\d{0,2})?/);
		// valorFormatado = valorFormatado.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
		if (matches && matches[1]) {
			matches.shift(); // remove primeiro elemento do array (string toda)
			const [ grupo1, grupo2, grupo3, grupo4, grupo5 ] =  matches;

			valorFormatado = grupo1;

			if (grupo2) {
				valorFormatado += `.${grupo2}`;

				if (grupo3) {
					valorFormatado += `.${grupo3}`;

					if (grupo4) {
						valorFormatado += `/${grupo4}`;

						if (grupo5) {
							valorFormatado += `-${grupo5}`;
						}
					}
				}
			}
		}
	}

	return valorFormatado;
}

/**
 * Recebe uma string e converte para o formato
 * (xx) xxxx-xxxx ou (xx) xxxxx-xxxx,
 * em que x é um caracter de 0 a 9
 */
export function formatarTelefone(telefone: string): string {
	let valorFormatado = telefone.replace(/\D/g, "");

	if (valorFormatado.length > 11)
		valorFormatado = valorFormatado.substring(0, 11);

	const matches = valorFormatado.match(/(\d{0,2})?(\d{0,9})?/);

	// matches[1] = DDD
	// matches[2] = dígitos do telefone sem o DDD
	if (matches && matches[1]) {
		matches.shift(); // remove primeiro elemento do array (string toda)
		const [ grupo1, grupo2 ] =  matches;

		valorFormatado = `(${grupo1}`;

		if (grupo2) {
			valorFormatado += ') ';

			if (grupo2.length < 9) {
				if (grupo2.length <= 4) 
					valorFormatado += grupo2.substring(0, grupo2.length);
				else // Quando o telefone tem mais que 4 dígitos, adiciona o '-'
					valorFormatado += `${grupo2.substring(0, 4)}-${grupo2.substring(4, grupo2.length)}`;
			}
			else { // O tamanho da string é igual a 9
				valorFormatado += `${grupo2.substring(0, 5)}-${grupo2.substring(5, 9)}`;
			}
		}
	}

	return valorFormatado;
}

export function gerarLinkCompleto(numeroRifa: string): string {
	if (process.env.REACT_APP_NOME_SISTEMA === "Rifa Tech")
		return `/rifa.xhtml?numeroRifa=${numeroRifa}`;
	
	return `/campanha?numero=${numeroRifa}`;
}