import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { getLink } from '../utils/FunctionUtils';

const Footer: React.FC = () => {
	return (
		<div id="footer-container" className="page-footer text-center pb-3">
			{/* Links para páginas do site e captação */}
			<div id="container-sitemap" className="panel-bg-primary pt-4">
				<div id="sitemap" className="row pb-4 px-2 mx-auto" style={{maxWidth: "871px"}}>
					<div className="col-6 col-md-3">
						<strong>LINKS</strong>

						<a href={`${process.env.REACT_APP_CONTENT_URL}/sobre-nos`} className="d-block link-footer">Sobre Nós</a>
						<a href={`${process.env.REACT_APP_CONTENT_URL}/termo-de-uso`} className="d-block link-footer">Termo de Uso</a>
						<a href={`${process.env.REACT_APP_CONTENT_URL}/blog`} className="d-block link-footer">Blog</a>
					</div>

					<div className="col-6 col-md-3">
						<strong>ÁREA DO CLIENTE</strong>

						<Link to="/comprar.xhtml" className="d-block link-footer">
							Comprar
						</Link>
						<Link to={getLink('criar_campanha', undefined, undefined)} className="d-block link-footer">Criar Campanha</Link>
						<Link to="/cadastrarUsuario.xhtml" className="d-block link-footer">Cadastrar</Link>
						<Link to="/login.xhtml" className="d-block link-footer">Entrar</Link>
					</div>

					<div className="pt-5 pt-md-0 col-12 col-md-6">
						<strong className="text-uppercase">Seja o primeiro a receber as novidades da Rifa Tech</strong>

						<form id="form-captacao" action={`${process.env.PUBLIC_URL}/captacao.xhtml`} method="GET" target="_blank">
							<div className="form-row align-items-center">
								<div className="col-12 col-md-8">
									<input type="email"
										name="email"
										className="form-control"
										id="email-newsletter-input"
										aria-describedby="emailHelp"
										placeholder="Seu e-mail"
										style={{borderRadius: 0}} />
									<input type="hidden" name="tipo" value="newsletter" />
								</div>

								<div className="mt-3 mt-md-0 col-12 col-md-4">
									<button type="submit" className="btn btn-sm btn-block btn-secondary">
										Inscrever
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			{/* Links para redes sociais e dados da empresa */}
			<div className="pt-4">
				{/* Redes Sociais */}
				<div id="footer-contato" className="mb-3">
					<Row id="footer-links-redes-sociais" className="mx-auto mb-1">
						<Col>
							<a href={process.env.REACT_APP_LINK_FACEBOOK} target="_blank" rel="noopener noreferrer">
								<span className="uil-facebook" />
							</a>
						</Col>

						<Col>
							<a href={process.env.REACT_APP_LINK_INSTAGRAM} target="_blank" rel="noopener noreferrer">
								<span className="uil-instagram" />
							</a>
						</Col>
					</Row>

					{/* E-mail atendimento */}
						sua@rifatech.com
				</div>

				{/* Link para a Dhoe */}
				<div className="mb-3 texto-dhoe">
					Clique <a href="https://dhoe.com.br" target="_blank" rel="noopener noreferrer" className="text-white">AQUI</a> e conheça a Dhoe, o site de vaquinhas da Rifa Tech, com a melhor tecnologia e o menor custo do mercado.
				</div>

				{/* Dados da Empresa */}
				<p className="px-2 mx-auto" style={{maxWidth: "800px"}}>
					<span className="d-block">
						<span>RT SOLUÇÕES DIGITAIS LTDA </span>
						<span className="d-none d-md-inline">| </span> 
						<span className="d-block d-md-inline">CNPJ 31.258.471/0001-28</span> 
					</span>
					© Copyright 2018 Rifa Tech
					<br />
					All Rights Reserved <br />
            </p>
			</div>
		</div>
	);
}

export default Footer;